<template>
  <div class="page-wrapper">
    <header class="navbar">
      <router-link to="/"><img alt="Showadz logo" class="logo" src="@/assets/img/showadz-logo.svg" /></router-link>
      <div class="right">
        <p class="regular-text">Already have an account?</p>
        <router-link to="/" class="bt">Login</router-link>
      </div>
    </header>

    <section class="form-wrap">
      <h1 class="big-title"></h1>
      <div class="inner-page-header"><h1 class="big-title">Create Your Account</h1></div>
      <p class="form-error" v-if="formError">
        {{ formError }}
      </p>
      <!-- <div class="step-wrap">
        <p class="regular-text">Step 1 of 2</p>
        <div class="step"><div class="bar"></div></div>
      </div> -->
      <form action="" class="step1" @submit="handleForm($event)">
        <div class="input-label-wrap" :class="{ filled: client.displayName }">
          <input v-model="client.displayName" type="text" class="label-focus" name="displayName" required />
          <p class="label">Your name</p>
        </div>
        <div class="input-label-wrap" :class="{ filled: client.businessName }">
          <input v-model="client.businessName" type="text" class="label-focus" name="businessName" required />
          <p class="label">Company name</p>
        </div>
        <div class="input-label-wrap" :class="{ filled: client.email }">
          <input v-model="client.email" type="email" class="label-focus" name="email" required />
          <p class="label">Work email address</p>
        </div>
        <div class="input-label-wrap" :class="{ filled: client.password }">
          <input v-model="client.password" type="password" class="label-focus" name="password" required />
          <p class="label">Password</p>
        </div>
        <!-- <p class="regular-text is-center">
          <strong>What's a good password?</strong> <br />
          <small
            >Must be at least 7 or 8 characters long — longer is better; <br />
            Have both uppercase and lowercase letters; <br />
            You can also have digits and/or punctuation</small
          >
        </p> -->
        <div class="password-message">
          <p class="regular-text" v-if="score == 0">&nbsp;</p>
          <p class="regular-text low" v-if="score == 1 || score == 2">Low</p>
          <p class="regular-text good" v-if="score == 3">Good</p>
          <p class="regular-text strong" v-if="score == 4">Strong</p>
        </div>
        
        <password ref="pwc" @score="showScore" v-model="client.password" :strength-meter-only="true" />

        <button type="submit" class="bt" :class="{ 'is-loading': isLoading }">
          <div class="bt-text">
            <p>Create</p>
            <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
          </div>
          <p class="loader">
            &nbsp;
            <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
              <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                </path>
              </g>
            </svg>
            &nbsp;
          </p>
        </button>
      </form>
    </section>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import firebase from 'firebase/app'
import { mapGetters } from 'vuex'
import { doAlert } from '@/misc/helpers'

export default {
  head: function () {
    return {
      title: {
        inner: 'Create an account',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} Create your account.`,
          id: 'desc',
        },
      ],
    }
  },

  components: { Password },

  watch: {
    isUserLoggedIn: {
      handler(isUserLoggedIn) {
        if (isUserLoggedIn) {
          this.logout()

          setTimeout(() => {
            window.location.reload()
          }, 755)
        } else {
          this.isLoading = false
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      client: {
        membership: 'FREE',
        selfCreated: true,
      },
      formError: null,
      isLoading: true,
      score: 0,
    }
  },

  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
  },

  methods: {
    async logout() {
      await firebase.auth().signOut()
    },
    async handleForm(event) {
      event.preventDefault()

      if (this.$refs.pwc.passwordStrength < 2 || this.$refs.pwc.passwordCount < 7 || !this.$refs.pwc.isSecure) {
        if (this.$refs.pwc.passwordCount < 7) {
          doAlert({
            type: 'error',
            title: 'Password must be at least 7 characters long',
          })
          return
        }
        doAlert({
          type: 'error',
          title: 'Password is not secured. Try adding uppercase and lowercase letters. You can also have digits and/or punctuation',
        })
        return
      }

      if (this.isLoading) {
        return
      }

      this.isLoading = true

      const { email, password } = this.client

      localStorage.setItem('account.creation.user.displayName', this.client.displayName)
      localStorage.setItem('account.creation.user.membership', this.client.membership)
      localStorage.setItem('account.creation.user.selfCreated', this.client.selfCreated)
      localStorage.setItem('account.creation.user.businessName', this.client.businessName)

      try {
        await firebase.auth().createUserWithEmailAndPassword(email, password)
        this.$router.push('/clients')
      } catch (error) {
        this.formError = error
        this.isLoading = false
      }
    },
    showScore (score) {
        this.score = score
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/forms.scss';
@import '@/theme/button.scss';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 50px;
  background-color: #fff;

  .logo {
    width: 145px;
    display: block;
  }

  .right {
    display: flex;
    align-items: center;

    .regular-text {
      margin-bottom: 0;
      margin-right: 20px;
      font-size: 14px;
    }

    .bt {
      min-width: 0;
      height: auto;
      padding: 12px 20px 12px 20px;
    }
  }
}

.inner-page-header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;
}

.page-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 3vw;

  h2 {
    color: $dark-blue;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .small-title {
    font-size: 16px;
  }

  p {
    color: $dark-grey;
    margin-bottom: 15px;
  }

  ul li {
    color: $dark-grey;
  }
}

.step-wrap {
  width: 100%;
  margin-bottom: 20px;
  .regular-text {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
  }
  .step {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .bar {
    width: 50%;
    height: 10px;
    background: linear-gradient(to right, #2bb0c5, #6bd8ba);
  }
}

.form-wrap {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;

  .input-label-wrap {
    margin-bottom: 5px;
    .label {
      font-size: 14px;
    }
    &.filled {
      .label {
        font-size: 12px;
        transform: translate(15px, -120%);
        color: #4628ff;
      }
    }
  }

  .bt {
    margin: 0 auto;

    .bt-text,
    .bt-text p {
      color: #fff;
    }
  }
}

p.form-error {
  padding: 20px;
  margin-bottom: 5px;
  text-align: center;
  color: $err;
}

.regular-text.is-center {
  text-align: center;
}

.Password {
  max-width: none !important;
}

.password-message {
  margin-top: 10px;
  .regular-text {
    font-weight: 600;
    font-size: 13px;
    width: 100%;
    text-align: center;

    &.low {
      color: #ff4500;
    }
    &.good {
      color: #9acd32;
    }
    &.strong {
      color: green;
    }
  }
}
</style>
